.shake:hover {
  animation: shake-in-out 0.2s;
}

@keyframes shake-in-out {
  25% {
    transform: rotate(6deg);
  }

  75% {
    transform: rotate(-12deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@media (min-width: 950px) and (max-width: 1050px) {
  .admin_table {
      display: grid;
      align-content: center;
      gap: 10px;
  }
  .admin_user_table{
    display: flex;
    align-items: center;
  }
}
