.scroll-container {
    overflow-x: hidden;
}

.scroll-container:hover {
    overflow-x: auto;
}
@media (max-width: 1024px) {
    .scroll-container {
        overflow-x: auto;
    }
   
}
.scroll-container::-webkit-scrollbar {
    height: 2px;
    width: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}
