@media (min-width: 950px) and (max-width: 1050px) {
    .nft_display {
        width: 100%;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .table_content {
        display: flex;
        align-items: center;
    }
}

.creative-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.creative-scrollbar::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 4px;
}

.creative-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.creative-scrollbar::-webkit-scrollbar-button {
    display: none;
}