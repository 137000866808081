.fade-up-in {
    animation: fadeUpIn 5s forwards;
  }
  
  @keyframes fadeUpIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    10% {
      opacity: 1;
      transform: translateY(0);
    }
    90% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
  }
  

  